import { HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, concat, interval, Observable, of, Subject, Subscription, throwError } from "rxjs";
import { catchError, exhaustMap, filter, switchMap, take, takeLast, takeWhile, tap } from "rxjs/operators";
import { KeycloakService } from "keycloak-angular";
import {
  AudienceViewModel,
  Campaign,
  CampaignCountRequestFactory,
  CampaignStatusEnum,
  CampaignViewModel,
  CappingLevel,
  ExecutionPlatformGroup,
  Profile,
  ProfileViewModel,
  QualifierCountRequestFactory,
  TreeViewModel,
  Universe
} from "radr-shared";
import { Location } from "@angular/common";
import MasterDetailService from "../lib/master-detail.service";
import clonedeep from "lodash.clonedeep";
import { ProfilesService } from "./profiles.service";
import { QualifiersService } from "./qualifiers.service";
import { ApplicationSettingsService } from "./application-settings.service";
import { defaultControlPercentage } from "@shared/consts";
import { ExecutionPlatformsService } from "./execution-platforms.service";
import { DialogModalComponent } from "@shared/components/dialog-modal/dialog-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { SnackBarStatus } from "@shared/utils/notify";
import { CampaignNotFoundError, errorFunctionNameFactory } from "@shared/models";
import { saveAs } from "file-saver";
import { UniversesService } from "./universes.service";
import { TasksService } from "./tasks.service";
import { CeleryTaskStatesEnum, ControlLevel } from "radr-shared/src/enums";
import { Rule } from "@shared/components/angular-query-builder";
import { ActivatedRoute, Router } from "@angular/router";
import { RoleAccessService } from "./role-access.service";

@Injectable({
  providedIn: "root"
})
export class CampaignBuilderService extends MasterDetailService<CampaignViewModel> implements OnDestroy {
  get isCampaignCountsLoading(): boolean {
    return this.campaignCountsTasksProcessing.length > 0;
  }
  public get isQualifierCountsLoading(): boolean {
    return this._isQualifierCountsLoading;
  }

  get firstPriority(): number {
    const campaign: CampaignViewModel = this.getSelectedItem();
    if (campaign && campaign?.profiles?.length > 0) {
      return campaign.profiles.find(p => p.priority === 0) ? 0 : 1;
    }
    return 1;
  }

  get includesTuneInProfile(): boolean {
    return !!this.getSelectedItem().profiles.find(p => p.profileType === 2);
  }

  get includesInactiveProfile(): boolean {
    return !!this.getSelectedItem().profiles.find(p => p.status !== CampaignStatusEnum.Active);
  }

  get includesErrorProfile(): boolean {
    return !!this.getSelectedItem().profiles.find(p => p.status === CampaignStatusEnum.Error);
  }

  get includesProcessingProfile(): boolean {
    return !!this.getSelectedItem().profiles.find(p => p.status === CampaignStatusEnum.Processing);
  }

  get campaignCountsEnabled(): boolean {
    return this.getSelectedItem().autoCalculateCounts;
  }
  set campaignCountsEnabled(value: boolean) {
    const campaign: CampaignViewModel = this.getSelectedItem();
    if (value && !this.campaignCountsEnabled) {
      campaign.autoCalculateCounts = value;
      this.loadCounts({ keepCache: false });
    } else {
      campaign.autoCalculateCounts = value;
    }
  }

  constructor(
    private executionPlatformService: ExecutionPlatformsService,
    private qualifiersService: QualifiersService,
    private profilesService: ProfilesService,
    private applicationSettingsService: ApplicationSettingsService,
    public dialog: MatDialog,
    private keycloak: KeycloakService,
    private universesService: UniversesService,
    private tasksService: TasksService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private roleAccessService: RoleAccessService
  ) {
    super("campaigns", false, CampaignViewModel);
  }
  readonly campaignList$: Observable<CampaignViewModel[]> = this.masterListSource$.asObservable();
  public campaignsTreeView: BehaviorSubject<TreeViewModel[]> = new BehaviorSubject<TreeViewModel[]>([]);
  public newProfileAddedToCampaign: Subject<{
    profile: ProfileViewModel;
    options: { highlight: boolean };
  }> = new Subject();
  public isLoadingTreeView: boolean = false;

  public counts: any = {};
  public campaignCountsTasksProcessing: string[] = [];

  public qualifierCounts: any = {};
  private qualifiersPendingCounts: Partial<Rule>[] = [];
  private _isQualifierCountsLoading: boolean = false;

  public focusedCampaignChanged$: Subject<CampaignViewModel> = new Subject<CampaignViewModel>();

  public countError: boolean = false;

  public qualifierCountErrors: Set<string> = new Set();

  private campaignStatusPoller: Subscription;
  private campaignCountsPoller: Subscription;
  public pendingQualifierCountRequest: Subscription;

  public static b64toBlob(b64Data: string, contentType: string = "", sliceSize: number = 512): Blob {
    const byteCharacters: string = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset: number = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice: string = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers: any[] = new Array(slice.length);
      for (let i: number = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray: Uint8Array = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob: Blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  ngOnDestroy(): void {
    if (this.campaignStatusPoller) {
      this.campaignStatusPoller.unsubscribe();
    }
    if (this.campaignCountsPoller) {
      this.campaignCountsPoller.unsubscribe();
    }
    if (this.pendingQualifierCountRequest) {
      this.pendingQualifierCountRequest.unsubscribe();
    }
  }

  selectItem(item: CampaignViewModel): void {
    const oldId: number = this.getSelectedItem()?.id;
    this.selectedItemSource$.next(item);
    this.counts = {};
    if (item.id !== oldId && !!oldId) {
      this.focusedCampaignChanged$.next(item);
    }
  }

  getCampaigns(): Observable<CampaignViewModel[]> {
    return this.updateMasterList();
  }

  getCampaign(campaignId: number): Observable<CampaignViewModel> {
    return this.getOne<CampaignViewModel>(`${this.route}/${campaignId}`);
  }

  getCampaignBuilderViewModel(campaignId: number): Observable<CampaignViewModel> {
    return this.getOne<CampaignViewModel>(`${this.route}/builder-viewmodel/${campaignId}`);
  }

  getCampaignStatus(campaignId: number): Observable<Campaign> {
    return this.getOne<Campaign>(`${this.route}/${campaignId}/status`, undefined, false);
  }

  getUpdatedRootProfile(profileId: number): Observable<Profile> {
    return this.getOne<Profile>(`profiles/${profileId}`);
  }

  getCampaignsTreeView(): Observable<TreeViewModel[]> {
    this.isLoadingTreeView = true;
    const inDataCatalog: string = this.roleAccessService.isLimitedAccess() ? `?inDataCatalog=true` : "";
    return super.getMany(`${this.route}/tree-view${inDataCatalog}`, undefined, false).pipe(
      tap((tree: TreeViewModel[]) => {
        this.isLoadingTreeView = false;
        this.campaignsTreeView.next(tree);
      })
    );
  }

  getUniverseForExecutionPlatformGroup(index: number): Universe {
    const campaign: CampaignViewModel = this.getSelectedItem();
    if (index >= 0 && campaign.executionPlatformGroups.length > index) {
      return this.universesService.findUniverseById(campaign.executionPlatformGroups[index]?.universeId);
    }
    return this.universesService.findUniverseById(1);
  }

  private getUniverseCounts(universeName: string): any {
    return this.counts[universeName] || {};
  }

  public getTotalHouseholdCount(universe?: Universe): number {
    return this.getUniverseCounts(universe?.name).CAMPAIGN_COUNT;
  }

  public getTotalTargetCount(universe?: Universe): number {
    let universeTargetCount: number = 0;
    const universeCounts: any = this.getUniverseCounts(universe?.name);

    Object.keys(universeCounts).forEach(key =>
      key.includes("TARGET_COUNT") ? (universeTargetCount += universeCounts[key]) : null
    );
    return universeTargetCount;
  }

  public getTotalDeviceCount(universe?: Universe): number {
    return this.getUniverseCounts(universe?.name).DEVICE_COUNT;
  }

  public getProfileCount(priority: number, isTarget: boolean, universeName: string): number {
    if (isTarget === null) {
      return this.getUniverseCounts(universeName)[`P${priority}_COUNT`];
    }
    const targetOrControl: string = isTarget ? "TARGET" : "CONTROL";
    return this.getUniverseCounts(universeName)[`P${priority}_${targetOrControl}_COUNT`];
  }

  public getQualifierCount(qualifierHash: string): number {
    return this.qualifierCounts[`Q_${qualifierHash.toUpperCase()}_COUNT`];
  }

  public hasQualifierCount(qualifierHash: string): boolean {
    return this.qualifierCounts[`Q_${qualifierHash.toUpperCase()}_COUNT`] !== undefined;
  }

  public getPercentageOfCampaign(profilePriority: number): number {
    const universe: Universe = this.getSelectedUniverses()[0];
    return this.getUniverseCounts(universe?.name)[`P${profilePriority}_PCT`];
  }

  public getSelectedUniverses(): Universe[] {
    const selectedUniverses: Universe[] = [];
    const campaign: CampaignViewModel = this.getSelectedItem();
    const completeExecutionPlatformGroups: ExecutionPlatformGroup[] = campaign.completedExecutionPlatformGroups;
    if (campaign.status.id === CampaignStatusEnum.PendingActivation && completeExecutionPlatformGroups.length > 0) {
      selectedUniverses.push(
        ...campaign.executionPlatformGroups
          .map((epg: ExecutionPlatformGroup) => epg.universe)
          .filter(universe => !!universe)
      );
    } else {
      Array.from(this.getSelectedItem().selectedUniverses.values()).forEach(selectedUniverseName => {
        selectedUniverses.push(
          this.universesService.universes.getValue().find(universe => universe.name === selectedUniverseName)
        );
      });
    }
    return Array.from(new Set(selectedUniverses));
  }

  doesCampaignMeetMinimumSubscriberCount(): boolean {
    const campaign: CampaignViewModel = this.getSelectedItem();

    if (this.isCampaignCountsLoading || !this.counts) {
      return false;
    }

    const selectedUniverses: Universe[] = this.getSelectedUniverses();

    if (!selectedUniverses || !selectedUniverses.length) {
      return false;
    }
    const subscriberMinimum: number = this.applicationSettingsService.subscriberMinimum;
    return campaign.profiles.every(profile => {
      return selectedUniverses?.every(universe => {
        const targetCount: number = this.getProfileCount(profile.priority, true, universe?.name);
        const controlCount: number = this.getProfileCount(profile.priority, false, universe?.name);
        if (targetCount === 0) {
          return controlCount >= subscriberMinimum;
        } else if (controlCount === 0) {
          return targetCount >= subscriberMinimum;
        } else {
          return targetCount >= subscriberMinimum && controlCount >= subscriberMinimum;
        }
      });
    });
  }

  public hasCountsForUniverse(universe?: Universe): boolean {
    return Object.keys(this.getUniverseCounts(universe?.name)).length > 0;
  }

  public loadQualifierCounts(qualifiers: Partial<Rule>[]): void {
    if (this.includesTuneInProfile) {
      this._isQualifierCountsLoading = false;
      this.qualifierCounts = {};
      return;
    }
    this._isQualifierCountsLoading = true;
    if (this.pendingQualifierCountRequest) {
      this.pendingQualifierCountRequest.unsubscribe();
    }
    this.qualifiersPendingCounts = this.qualifiersPendingCounts.concat(qualifiers);
    qualifiers.forEach(qualifier => {
      const hash: string = this.qualifiersService.createQualifierHash(
        qualifier.field,
        qualifier.value,
        qualifier.operator,
        qualifier.isControl
      );
      this.qualifierCountErrors.delete(hash);
    });
    this.pendingQualifierCountRequest = super
      .post<any>(
        `subscriber/count/qualifier/v2`,
        QualifierCountRequestFactory.create(this.qualifiersPendingCounts, this.getSelectedItem())
      )
      .pipe(
        catchError(err => {
          qualifiers.forEach(qualifier => {
            const hash: string = this.qualifiersService.createQualifierHash(
              qualifier.field,
              qualifier.value,
              qualifier.operator,
              qualifier.isControl
            );
            this.qualifierCountErrors.add(hash);
          });
          this._isQualifierCountsLoading = false;
          return throwError(errorFunctionNameFactory(err, "SubscriberService.getQualifierCounts"));
        })
      )
      .subscribe((counts: any) => {
        this.qualifierCounts = Object.assign(this.qualifierCounts || {}, counts);
        this._isQualifierCountsLoading = false;
        this.qualifiersPendingCounts = [];
      });
  }

  public loadCounts(options?: {
    universes?: Universe[];
    loadQualifierCounts?: boolean;
    keepCache?: boolean;
    force?: true;
    loadTuneIn?: boolean;
    audience?: AudienceViewModel;
  }): void {
    const tuneInStatus: any = options?.loadTuneIn ? { status: { id: 3 } } : {};
    if ((!this.campaignCountsEnabled && !options?.force) || (!options?.loadTuneIn && this.includesTuneInProfile)) {
      this.getSelectedItem().profiles.map(p => (p.status = p.isRoot ? CampaignStatusEnum.PendingProcessing : p.status));
      this.counts = {};
      return;
    }
    const campaign: CampaignViewModel = this.getSelectedItem();
    if (options?.audience) {
      campaign.profiles[0].definition = options.audience.definition;
      if (options.audience.isCappingActive) {
        campaign.cappingLevel = options.audience.audienceCappingType;
        campaign.profiles[0].capFixed = options.audience.capFixed ?? null;
        campaign.profiles[0].capPercentage = options.audience.capPercentage ?? null;
      }
      if (options.audience.isControlActive) {
        campaign.controlLevel = ControlLevel.Audience;
        campaign.profiles[0].controlPercentage = options.audience.controlPercentage ?? null;
      }
    }
    this.countError = false;
    if (this.campaignCountsPoller) {
      this.campaignCountsPoller.unsubscribe();
    }
    this.campaignCountsTasksProcessing = [];
    if (this.hasAtLeastOneProfileWithCompleteQualifier(campaign) || this.campaignHasTargetAudience(campaign)) {
      if (!options?.keepCache) {
        this.counts = {};
      }
      if (options?.loadQualifierCounts) {
        this._isQualifierCountsLoading = true;
        this.getSelectedItem().profiles?.forEach(profile => this.loadQualifierCounts(profile.definition.rules));
      }
      campaign.isLoadingCounts = true;
      super
        .post<any>(
          `subscriber/count/campaign/v3`,
          CampaignCountRequestFactory.create(
            { ...this.getSelectedItem(), ...tuneInStatus },
            options?.universes || this.getSelectedUniverses()
          )
        )
        .pipe(
          catchError(err => {
            this.campaignCountsTasksProcessing = [];
            this.countError = true;
            return throwError(errorFunctionNameFactory(err, "SubscriberService.getCampaignCounts"));
          })
        )
        .subscribe((countsTaskId: string) => {
          this.campaignCountsTasksProcessing.push(countsTaskId);
          this.campaignCountsPoller = this.taskPoll({ taskId: countsTaskId }).subscribe(finishedTask => {
            if (finishedTask.state === CeleryTaskStatesEnum.FAILURE) {
              this.countError = true;
            }
            this.counts = Object.assign(this.counts || {}, finishedTask.result);
            this.campaignCountsTasksProcessing = this.campaignCountsTasksProcessing.filter(
              id => id !== finishedTask.id
            );
            campaign.isLoadingCounts = false;
          });
        });
    } else {
      this.counts = {};
      this.qualifierCounts = {};
    }
  }

  taskPoll(options: { taskId: string; isQualifierTask?: boolean; pollingFrequencyMs?: number }): Observable<any> {
    const pollingInterval$: BehaviorSubject<number> = new BehaviorSubject(500);
    let stopPolling: boolean = false;
    const poll: Observable<any> = pollingInterval$.pipe(switchMap(value => interval(value))).pipe(
      takeWhile(() => !stopPolling),
      exhaustMap(() => this.tasksService.getTaskStatus(options.taskId)),
      filter(finishedTask => ["SUCCESS", "FAILURE"].includes(finishedTask.state)),
      tap(() => {
        stopPolling = true;
        clearTimeout(firstTimeout);
        clearTimeout(secondTimeout);
        clearTimeout(thirdTimeout);
      })
    );

    const firstTimeout: NodeJS.Timeout = setTimeout(() => pollingInterval$.next(1000), 2000);
    const secondTimeout: NodeJS.Timeout = setTimeout(() => pollingInterval$.next(2000), 12000);
    const thirdTimeout: NodeJS.Timeout = setTimeout(() => pollingInterval$.next(5000), 42000);

    return poll;
  }

  campaignStatusPoll(): void {
    const campaign: CampaignViewModel = this.getSelectedItem();
    if (
      campaign?.status?.id === CampaignStatusEnum.PendingActivation ||
      campaign?.status?.id === CampaignStatusEnum.Exporting ||
      campaign?.status?.id === CampaignStatusEnum.PendingProcessing ||
      campaign?.status?.id === CampaignStatusEnum.Processing ||
      campaign.isProcessingProfileIncluded
    ) {
      if (this.campaignStatusPoller) {
        this.campaignStatusPoller.unsubscribe();
      }
      this.campaignStatusPoller = interval(5000)
        .pipe(
          takeWhile(
            () =>
              campaign.status.id === CampaignStatusEnum.Exporting ||
              campaign.status.id === CampaignStatusEnum.Processing ||
              campaign.status.id === CampaignStatusEnum.PendingProcessing ||
              campaign.isProcessingProfileIncluded
          )
        )
        .subscribe(() => {
          this.getCampaignStatus(campaign.id).subscribe(currentCampaign => {
            campaign.status = currentCampaign.status;
            campaign.profiles.forEach(prof => {
              prof.status = currentCampaign.profiles.find(p => p.id === prof.id).status;
            });
            if (campaign.status?.id === CampaignStatusEnum.Active) {
              this.loadCounts({ force: true });
            }
            this.onSelectedItemPropertyChanged();
          });
        });
    }
  }

  profileStatusPoll(currentProfile: ProfileViewModel): void {
    if (!currentProfile) {
      return;
    }
    if (currentProfile.status === CampaignStatusEnum.PendingProcessing) {
      this.campaignStatusPoller = interval(5000)
        .pipe(
          takeWhile(() => {
            return (
              currentProfile.status === CampaignStatusEnum.Processing ||
              currentProfile.status === CampaignStatusEnum.PendingProcessing
            );
          })
        )
        .subscribe(() => {
          this.getUpdatedRootProfile(currentProfile.id).subscribe(updatedProfile => {
            currentProfile.status = updatedProfile.status;
            if (
              updatedProfile.status === CampaignStatusEnum.Active &&
              !this.getSelectedItem().profiles.find(p => p.status !== CampaignStatusEnum.Active)
            ) {
              const isTuneIn: boolean = updatedProfile.profileType === 2;
              this.loadCounts({ force: true, loadTuneIn: isTuneIn });
            }
          });
        });
    } else if (
      currentProfile.status === CampaignStatusEnum.Active &&
      !this.getSelectedItem().profiles.find(p => p.status !== CampaignStatusEnum.Active)
    ) {
      const isTuneIn: boolean = currentProfile.profileType === 2;
      this.loadCounts({ force: true, loadTuneIn: isTuneIn });
    }
  }

  getAudienceCount(isTarget: boolean, universeName: string): number {
    return isTarget
      ? this.getUniverseCounts(universeName).P1_TARGET_COUNT
      : this.getUniverseCounts(universeName).P1_CONTROL_COUNT;
  }

  // audienceStatusPoll(currentAudience: AudienceViewModel): void {
  // TODO: DETERMINE IF WE NEED THIS
  // if (!currentProfile) {
  //   return;
  // }
  // if (currentProfile.status === CampaignStatusEnum.PendingProcessing) {
  //   this.campaignStatusPoller = interval(5000)
  //     .pipe(
  //       takeWhile(() => {
  //         return (
  //           currentProfile.status === CampaignStatusEnum.Processing ||
  //           currentProfile.status === CampaignStatusEnum.PendingProcessing
  //         );
  //       })
  //     )
  //     .subscribe(() => {
  //       this.getUpdatedRootProfile(currentProfile.id).subscribe(updatedProfile => {
  //         currentProfile.status = updatedProfile.status;
  //         if (
  //           updatedProfile.status === CampaignStatusEnum.Active &&
  //           !this.getSelectedItem().profiles.find(p => p.status !== CampaignStatusEnum.Active)
  //         ) {
  //           const isTuneIn: boolean = updatedProfile.profileType === 2;
  //           this.loadCounts({ force: true, loadTuneIn: isTuneIn });
  //         }
  //       });
  //     });
  // } else if (
  //   currentProfile.status === CampaignStatusEnum.Active &&
  //   !this.getSelectedItem().profiles.find(p => p.status !== CampaignStatusEnum.Active)
  // ) {
  //   const isTuneIn: boolean = currentProfile.profileType === 2;
  //   this.loadCounts({ force: true, loadTuneIn: isTuneIn });
  // }
  // }

  saveCampaign(
    campaign: CampaignViewModel,
    selectCampaignAfterSave: boolean = true,
    skipExecutionPlatformCheck: boolean = false,
    clonedFromSummaryPage: boolean = false
  ): Observable<CampaignViewModel> {
    const campaignToSave: CampaignViewModel = clonedeep(campaign);
    if (campaignToSave.isCappingActive) {
      this.setLegacyCapFields(campaignToSave);
    }
    campaignToSave.universes = !clonedFromSummaryPage ? this.getSelectedUniverses() : campaignToSave.universes;
    delete campaignToSave.qualifiers;
    delete campaignToSave.selectedUniverses;
    delete campaignToSave.onPropertyChange$;
    delete campaignToSave.topics;

    return this.checkCanSave(skipExecutionPlatformCheck).pipe(
      switchMap(canSave => {
        return canSave
          ? campaign.id
            ? this.upsert(`${this.route}/${campaign.id}`, campaignToSave)
            : this.save(this.route, campaignToSave)
          : of(null);
      }),
      tap((savedCampaign: CampaignViewModel) => {
        if (selectCampaignAfterSave && savedCampaign) {
          this.selectItem(new CampaignViewModel(savedCampaign));
        }
      })
    );
  }

  setLegacyCapFields(campaign: CampaignViewModel): void {
    campaign.profiles.forEach(profile => {
      profile.subscriberCap =
        this.getProfileCount(profile.priority, true, "All Subscribers") +
        this.getProfileCount(profile.priority, false, "All Subscribers");
    });
  }

  patchCampaign(patchValues: any): Observable<CampaignViewModel> {
    if (!patchValues.hasOwnProperty("id")) {
      throw new CampaignNotFoundError("Can't update campaign, id not found.", "campaignBuilderServicepatchCampaign");
    }
    return this.update(`${this.route}/${patchValues.id}`, patchValues);
  }

  generateReport(campaign: CampaignViewModel, fromBuilder: boolean = false): void {
    this.loadingService.setLoading(true);
    campaign.universes = fromBuilder ? this.getSelectedUniverses() : campaign.universes;
    this.post(`${this.route}/report`, campaign)
      .pipe(take(1))
      .subscribe(
        async ({ b64 }) => {
          const blob: Blob = CampaignBuilderService.b64toBlob(
            b64,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          const currentDateTimeForFileName: string = new Date()
            .toLocaleString()
            .replace(/[^0-9]/gi, "")
            .toLowerCase();

          const filename: string = `${campaign.name} ${currentDateTimeForFileName}.xlsx`;

          saveAs(blob, filename);

          this.snackbarService.open(
            `Report Successfully Generated for Campaign ${campaign.name}`,
            null,
            5000,
            SnackBarStatus.Success
          );
        },
        () => {
          this.snackbarService.open(
            `Report Failed to Generate for Campaign ${campaign.name}`,
            "OK",
            10000,
            SnackBarStatus.Error
          );
          this.loadingService.setLoading(false);
        },
        () => {
          this.loadingService.setLoading(false);
        }
      );
  }

  isCampaignCanoe(): boolean {
    const campaign: CampaignViewModel = this.getSelectedItem();
    return campaign?.executionPlatformGroups?.some(
      ep => ep?.executionPlatformId === 6 || ep?.executionPlatformId === 7
    );
  }

  isCampaignFullAvail(): boolean {
    const campaign: CampaignViewModel = this.getSelectedItem();
    return campaign?.executionPlatformGroups?.some(ep => {
      return (
        ep?.executionPlatformId &&
        this.executionPlatformService.isExecutionPlatformFullAvail(ep.executionPlatformId, ep.executionPlatform)
      );
    });
  }
  isCampaignUnique(campaign: Partial<CampaignViewModel>): Observable<boolean> {
    let params: HttpParams = new HttpParams();
    for (const field in campaign) {
      if (campaign[field]) {
        params = params.set(field, campaign[field]);
      }
    }

    return this.getOne(`${this.route}/isUnique`, { params }, false);
  }

  saveCampaignFromDataSource(dataSourceId: number, campaign: Partial<Campaign>): Observable<Campaign> {
    return this.post(`${this.route}/create-campaign-from-datasource/${dataSourceId}`, campaign);
  }

  updateProfileControlPercentages(newPercentage: number | null): void {
    newPercentage = newPercentage >= 0 ? newPercentage : null;
    const campaign: CampaignViewModel = this.getSelectedItem();
    campaign.profiles.forEach((_p, idx: number) => {
      campaign.profiles[idx].controlPercentage = newPercentage;
    });

    this.onSelectedItemPropertyChanged();
  }

  deleteCampaign(campaignId: number): Observable<any> {
    return concat(this.delete(`${this.route}/${campaignId}`), of(super.selectItem(null)), this.getCampaigns()).pipe(
      takeLast(1)
    );
  }

  cloneCampaign(originalCampaign: CampaignViewModel, newCampaign: CampaignViewModel): void {
    newCampaign.profiles = originalCampaign.profiles.map(oldProfile => {
      const newProfile: ProfileViewModel = new ProfileViewModel();
      newProfile.priority = oldProfile.priority;
      newProfile.definition = oldProfile.definition;
      newProfile.profileType = oldProfile.profileType;
      newProfile.status = newCampaign.status?.id;
      newProfile.controlPercentage = null;
      newProfile.validatedBatchValues = oldProfile.validatedBatchValues;
      newProfile.name = oldProfile.name + " (Copy)";
      return new ProfileViewModel(newProfile);
    });

    // the following should be cloned from the original campaign:
    newCampaign.isGross = originalCampaign.isGross;
    newCampaign.isAddressable = originalCampaign.isAddressable;
    newCampaign.universes = originalCampaign.universes;
    newCampaign.profiles.forEach(profile => {
      profile.capFixed = null;
      profile.capPercentage = null;
    });

    // the following properties should not be cloned:
    newCampaign.isControlActive = false;
    newCampaign.controlLevel = 0;
    newCampaign.controlPercentage = null;
    newCampaign.firstActivatedDateTime = null;
    newCampaign.isActive = false;
    newCampaign.isCappingActive = false;
    newCampaign.capFixed = null;
    newCampaign.cappingLevel = CappingLevel.Off;
    newCampaign.qualifiers = [];
    const isClonedFromExportSettings: boolean = this.location.path().includes("tab=exportSettings");

    if (this.location.path().indexOf("builder") > -1) {
      this.selectItem(new CampaignViewModel(newCampaign));
      this.onSelectedItemPropertyChanged();
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: isClonedFromExportSettings ? { tab: "exportSettings" } : {}
      });
      this.snackbarService.open("Clone successful", null, 5000, SnackBarStatus.Success);
      this.loadCounts();
    } else {
      this.loadingService.setLoading(true);
      this.saveCampaign(newCampaign, false, false, true).subscribe(() => {
        this.loadingService.setLoading(false);
        window.location.reload();
      });
    }
  }

  selectNewCampaign(): Observable<CampaignViewModel> {
    return this.getOne<CampaignViewModel>(`${this.route}/create-builder-viewmodel`).pipe(
      tap(campaign => {
        campaign.createdBy = this.keycloak.getUsername();
        this.selectItem(campaign);
      })
    );
  }

  addNewProfile(profile?: ProfileViewModel, priority?: number, highlightInBuilder?: boolean): ProfileViewModel {
    const campaign: CampaignViewModel = this.getSelectedItem();
    let newProfile: ProfileViewModel = new ProfileViewModel();

    if (profile) {
      newProfile = clonedeep(profile);

      if (priority || priority === 0) {
        campaign.profiles.forEach(p => {
          if (p.priority >= priority && priority !== 0) {
            p.priority += 1;
          }
        });
        newProfile.priority = priority;
      } else {
        newProfile.priority = campaign.profiles.length + this.firstPriority;
      }
    } else {
      newProfile.definition = { condition: "and", rules: [] };
      newProfile.priority = campaign.profiles.length + this.firstPriority;
    }

    newProfile.campaignId = campaign.id;
    newProfile.id = null;
    newProfile.simplifiProfileId = null;
    newProfile.name = null;
    newProfile.description = null;
    newProfile.exportKey = null;
    newProfile.capFixed = null;
    newProfile.capPercentage = null;
    newProfile.status = campaign.status.id;
    newProfile.createdBy = this.keycloak.getUsername();
    newProfile.profileType = profile?.profileType ? profile.profileType : 1;
    newProfile.exportKey = campaign.groupName
      ? campaign.groupName + String(newProfile.priority).padStart(2, "0")
      : null;

    if (this.isCampaignFullAvail() && campaign.groupName) {
      newProfile.exportKey = this.profilesService.getProfileExportKeyByGroupNameAndPriority(
        campaign.groupName,
        newProfile.priority
      );
    }

    if (campaign.isControlActive) {
      if (campaign.isControlLevelCampaign) {
        newProfile.controlPercentage = this.getSelectedItem().controlPercentage || defaultControlPercentage;
      } else {
        newProfile.controlPercentage = defaultControlPercentage;
      }
    } else {
      newProfile.controlPercentage = null;
    }

    campaign.profiles.push(newProfile);
    campaign.profiles.sort((a, b) => a.priority - b.priority);
    this.newProfileAddedToCampaign.next({ profile: newProfile, options: { highlight: true } });
    this.onSelectedItemPropertyChanged();

    return newProfile;
  }

  addNewAudience(audience?: AudienceViewModel, highlightInBuilder?: boolean): AudienceViewModel {
    return new AudienceViewModel();
  }

  checkCanSave(skipExecutionPlatformCheck: boolean): Observable<boolean> {
    return skipExecutionPlatformCheck ? of(true) : this.checkForCampaignActivationWarnings();
  }

  checkForCampaignActivationWarnings(): Observable<boolean> {
    const campaign: CampaignViewModel = this.getSelectedItem();
    if (
      campaign &&
      !campaign.isCampaignActivatedOrActivating &&
      campaign.completedExecutionPlatformGroups?.length > 0
    ) {
      return this.dialog
        .open(DialogModalComponent, {
          data: {
            title: "Campaign Not Activated",
            content:
              "This campaign has been assigned an execution platform but is not set to active.\n\nSaving will not send campaign to execution platform.",
            submitButtonText: "OK",
            cancelButtonText: "Cancel"
          }
        })
        .afterClosed()
        .pipe(take(1));
    } else if (
      campaign &&
      campaign.isCampaignActivatedOrActivating &&
      campaign.completedExecutionPlatformGroups?.filter(epg => !!epg.executionPlatformId).length === 0
    ) {
      return this.dialog
        .open(DialogModalComponent, {
          data: {
            title: "No Execution Platform Selected",
            content:
              "You are activating a campaign with no execution platforms selected.\n\nSaving will not send campaign to execution platform.",
            submitButtonText: "OK",
            cancelButtonText: "Cancel"
          }
        })
        .afterClosed()
        .pipe(take(1));
    } else {
      return of(true);
    }
  }

  doesCampaignHavePartialExecutionPlatform(campaign: CampaignViewModel): boolean {
    return campaign.executionPlatformGroups
      ? campaign.executionPlatformGroups.some(platformGroup => {
          if (!platformGroup.executionPlatformId && !platformGroup.productGroupId && !platformGroup.requesterGroupId) {
            return false;
          } else {
            return (
              !platformGroup.executionPlatformId || !platformGroup.productGroupId || !platformGroup.requesterGroupId
            );
          }
        })
      : false;
  }

  doesCampaignOnlyHaveDefaultExecutionPlatformGroup(campaign: CampaignViewModel): boolean {
    return (
      campaign.executionPlatformGroups.length === 1 &&
      !campaign.executionPlatformGroups[0].requesterGroupId &&
      !campaign.executionPlatformGroups[0].productGroupId &&
      !campaign.executionPlatformGroups[0].executionPlatformId
    );
  }

  doesEveryProfileWithCanoeQualifierHaveSegmentId(campaign: CampaignViewModel): boolean {
    if (campaign.profiles?.length > 0) {
      return campaign.profiles.every((profile: ProfileViewModel) => {
        const hasSegmentIds: boolean =
          this.qualifiersService.getUniqueSegmentIdsFromRuleSet(profile.definition).length > 0;
        if (!hasSegmentIds) {
          return true;
        } else {
          return !!profile.segmentId;
        }
      });
    }
  }

  public hasAtLeastOneProfileWithCompleteQualifier(campaign: CampaignViewModel): boolean {
    if (campaign.profiles?.length > 0) {
      return campaign
        .filterOutDefaultProfile(campaign.profiles)
        .some(
          (profile: ProfileViewModel) =>
            profile?.definition?.rules?.length > 0 &&
            this.qualifiersService.isRuleSetPartiallyComplete(profile.definition)
        );
    }
    return false;
  }
  public campaignHasTargetAudience(campaign: CampaignViewModel): boolean {
    if (campaign.profiles?.length > 0) {
      return campaign
        .filterOutDefaultProfile(campaign.profiles)
        .some(
          (profile: ProfileViewModel) =>
            profile?.targetAudienceId !== null &&
            profile?.targetAudienceId !== undefined &&
            profile?.targetAudienceId > 0
        );
    }
    return false;
  }

  public everyQualifierInCampaignIsComplete(campaign: CampaignViewModel): boolean {
    if (campaign.profiles?.length > 0) {
      return campaign
        .filterOutDefaultProfile(campaign.profiles)
        .every(
          (profile: ProfileViewModel) =>
            profile?.definition?.rules?.length > 0 && this.qualifiersService.isRuleSetComplete(profile.definition)
        );
    }
    return false;
  }

  doesCampaignHaveCanoeVideo(campaign: CampaignViewModel): boolean {
    return campaign.executionPlatformGroups.some(epg => epg.executionPlatformId === 6);
  }

  doesCampaignHaveCanoeATV(campaign: CampaignViewModel): boolean {
    return campaign.executionPlatformGroups.some(epg => epg.executionPlatformId === 7);
  }

  doesCampaignHaveSimplifi(): boolean {
    const campaign: CampaignViewModel = this.getSelectedItem();
    return campaign.executionPlatformGroups.some(epg => epg.executionPlatformId === 9);
  }

  everyProfileSimplifiComplete(): boolean {
    const campaign: CampaignViewModel = this.getSelectedItem();
    return campaign.profiles.every(profile => {
      return profile.simplifiCategory && profile.simplifiSubcategory && profile.simplifiAttribute;
    });
  }

  doesCampaignHaveDataCatalogProfile(): boolean {
    const campaign: CampaignViewModel = this.getSelectedItem();
    return campaign.profiles.some(profile => profile.inDataCatalog);
  }

  everyProfileHasDataCatalog(): boolean {
    const campaign: CampaignViewModel = this.getSelectedItem();
    return campaign.profiles.every(profile => {
      return profile.inDataCatalog;
    });
  }

  public getCampaignSaveErrorMessage(campaign: CampaignViewModel): string {
    let errorMessage: string = "";
    if (!campaign.name) {
      errorMessage = "Campaign cannot be saved without a name";
    }
    return errorMessage;
  }

  public setCanoeCampaignControlValues(canoeCampaignName: string, canoeCampaignId: string): void {
    const campaign: CampaignViewModel = this.getSelectedItem();
    const groupsNeedingCanoeData: ExecutionPlatformGroup[] = campaign.executionPlatformGroups.filter(epg =>
      [6, 7].includes(epg.executionPlatformId)
    );
    if (groupsNeedingCanoeData.length) {
      if (groupsNeedingCanoeData.some(epg => epg.executionPlatformId === 6)) {
        if (!campaign.canoeVideoCampaignName) {
          campaign.canoeVideoCampaignName = canoeCampaignName;
        }
        if (!campaign.canoeVideoCampaignId) {
          campaign.canoeVideoCampaignId = canoeCampaignId;
        }
      }
      if (groupsNeedingCanoeData.some(epg => epg.executionPlatformId === 7)) {
        if (!campaign.canoeATVCampaignName) {
          campaign.canoeATVCampaignName = canoeCampaignName;
        }
        if (!campaign.canoeATVCampaignId) {
          campaign.canoeATVCampaignId = canoeCampaignId;
        }
      }
    }
  }

  public getCampaignActivationErrorMessage(): string {
    const campaign: CampaignViewModel = this.getSelectedItem();
    let errorMessage: string = "";
    // Campaigns must have start and end dates in order to be activated
    if (!(campaign.startDate && campaign.endDate)) {
      errorMessage = "Campaigns cannot be activated without start and end dates";
    }

    // Default profile related checks
    if (campaign.hasDefaultProfile && !this.isCampaignFullAvail()) {
      errorMessage =
        "Campaigns cannot be activated that have a default profile but don’t have Visible World: Full Avail as the execution platform.";
    } else if (!campaign.hasDefaultProfile && this.isCampaignFullAvail()) {
      errorMessage =
        "Campaigns cannot be activated that have Visible World: Full Avail as the execution platform but don’t have a default profile set up.";
    }

    // If campaign is Full Avail group export key is not filled out properly
    if (this.isCampaignFullAvail() && !campaign.groupName) {
      errorMessage = "Full Availbility campaigns cannot be activated until there is a group export key.";
    }

    // If campaign has Canoe profiles without defined segmentIds at profile level
    if (this.isCampaignCanoe() && campaign.profiles.some(profile => !profile.segmentId || !profile.segmentName)) {
      errorMessage =
        // tslint:disable-next-line: max-line-length
        "All profiles must contain at least one qualifier with a Segment ID and Segment Name to be exported to Canoe.";
    }

    // If campaign has Canoe profiles without defined segmentIds at profile level
    if (!this.doesEveryProfileWithCanoeQualifierHaveSegmentId(campaign)) {
      errorMessage =
        // tslint:disable-next-line: max-line-length
        "Campaigns can not be activated until a Canoe Segment ID is selected when there are 2 or more Canoe qualifiers in a single profile.";
    }

    // Canoe campaigns can only be export with gross counts, not deduped
    if (!!campaign.executionPlatformGroups.find(ep => [6, 7].includes(ep.executionPlatformId)) && !campaign.isGross) {
      errorMessage = "Campaigns with Canoe as the execution platform must have Gross count selected.";
    }

    if (
      (this.doesCampaignHaveCanoeVideo(campaign) &&
        !(campaign.canoeVideoCampaignName && campaign.canoeVideoCampaignId)) ||
      (this.doesCampaignHaveCanoeATV(campaign) && !(campaign.canoeATVCampaignName && campaign.canoeATVCampaignId))
    ) {
      errorMessage =
        "Campaigns with Canoe as the execution platform must provide a Canoe Campaign Name and ID in the Export Settings tab.";
    }

    // simplifi campaigns must have categories etc. filled out on every profile
    if (this.doesCampaignHaveSimplifi() && !this.everyProfileSimplifiComplete()) {
      errorMessage =
        "Campaigns with Simplifi/Frequence as the execution platform must have Category/Sub-Category/Attribute details filled in on every profile.";
    }

    if (campaign.isCappingActive) {
      if (
        campaign.cappingLevel === CappingLevel.ProfileFixed &&
        !campaign.profiles.every(profile => profile.capFixed > 0)
      ) {
        errorMessage = "Each profile must have a fixed cap";
      } else if (
        campaign.cappingLevel === CappingLevel.ProfilePercentage &&
        !campaign.profiles.every(profile => profile.capPercentage > 0)
      ) {
        errorMessage = "Each profile must have a percentage cap";
      }
    }
    switch (campaign?.status.id) {
      case CampaignStatusEnum.Exporting:
      case CampaignStatusEnum.Processing:
      case CampaignStatusEnum.PendingProcessing:
        errorMessage = "Processing";
        break;
      case CampaignStatusEnum.Error:
        errorMessage = "An error occurred while attempting to activate this campaign.";
        break;
      case CampaignStatusEnum.Active:
        if (campaign.destinationProfiles.length > 0) {
          errorMessage =
            "Active campaigns containing root profiles can not be inactivated. Profiles are being used as qualifiers in:";
          campaign.destinationProfiles.forEach(profile => {
            errorMessage += `\n${profile.name}`;
          });
        }
        break;
    }

    if (campaign?.isProcessingProfileIncluded) {
      errorMessage = "Processing";
    } else if (campaign?.isErrorProfileIncluded) {
      errorMessage = "An error occurred while attempting to activate this campaign.";
    }

    // If campaign is not yet active, don't allow it to activate unless all profiles are above the minimum subscriber threshold
    if (campaign && !campaign.isCampaignActivated && !this.doesCampaignMeetMinimumSubscriberCount()) {
      errorMessage =
        "Campaigns cannot be activated when the counts are below the required minimum in any single profile.";
    }

    if (
      campaign &&
      !campaign.isCampaignActivated &&
      !campaign.addressableTypeId &&
      campaign.executionPlatformGroups.some(e => e.executionPlatformId === 1)
    ) {
      errorMessage = "Freewheel campaigns cannot be activated until an addressable type is set.";
    }

    // If campaign has a partially filled out execution platform group
    if (this.doesCampaignHavePartialExecutionPlatform(campaign)) {
      errorMessage = "All Execution Platform groups must fully be filled out in order to activate the campaign";
    }

    // If profiles are not filled out properly
    if (campaign.profiles.some(profile => !profile.exportKey)) {
      errorMessage = "Campaigns cannot be activated until there is an export key in each profile.";
    }

    // If any profile has a qualifier that is incomplete
    if (!this.everyQualifierInCampaignIsComplete(campaign)) {
      errorMessage = "Campaigns cannot be activated unless every qualifier is complete.";
    }

    // If campaign does not have at least 1 profile with 1 qualifier
    if (!campaign.isQualifierPresentInAllNonDefaultProfiles()) {
      errorMessage = "Campaigns cannot be activated unless all non-default profiles have at least 1 qualifier.";
    }

    // If one profile is Data Catalog all profiles must be Data Catalog
    if (this.doesCampaignHaveDataCatalogProfile() && !this.everyProfileHasDataCatalog()) {
      errorMessage =
        "Campaigns cannot be activated unless all profiles are Data Catalog or none of the profiles are Data Catalog.";
    }

    const saveErrorMessage: string = this.getCampaignSaveErrorMessage(campaign);
    if (saveErrorMessage) {
      errorMessage = saveErrorMessage;
    }

    return errorMessage;
  }
}
